$fff: #ffffff;
$gray_100: #f9fafb;
$gray_200: #f4f6f8;
$gray_300: #dfe3e8;
$gray_400: #c4cdd5;
$gray_500: #919eab;
$gray_600: #637381;
$gray_700: #454f5b;
$gray_800: #212b36;
$gray_900: #161c24;

$primary_100: #3b82f6;
$primary_200: #3b83f662;
$primary_light: #004df31e;
